import React, { useState } from 'react';

import {BsFillPlayFill, BsPauseFill} from 'react-icons/bs'

import {meal} from '../../constants'

import './Intro.css';

const Intro = () => {

  const [playVideo, setPlayVideo] = useState(false)
  const vidRef= React.useRef()
  const handleVideo=()=>{

    setPlayVideo((prePlayVideo)=>!prePlayVideo)

    if(playVideo){
      vidRef.current.pause()
    }else{
      vidRef.current.play()
    }

  }
    return (
      <div className='app__Video'>
        <video src={meal} type="video/mp4"
          ref={vidRef}
          loop
          controls={false}
          muted
          / >
        <div className='app__Video-overlay flex__center'>
          <div className='app__Video-overlay_circle flex__center'
            onClick={handleVideo}>
              {playVideo? 
                <BsFillPlayFill  color='#fff' fontSize={30}/> : <BsPauseFill color='#fff' fontSize={30} />
              }

          </div>
        </div>
       
      
      </div>
);
    
}
  

export default Intro;
